<template>
	<div class="landing-bg full-page">
		<navbar/>
		<template>
			<div class="landing-hero">
				<h1 class="login-small-title">LA PLATEFORME DE CANDIDATURE ET D’INSCRIPTION AU CYCLE DOCTORAL</h1>
				<h2 class="login-title text-bold">ESPACE DE CONNEXION</h2>

				<div class="ui grid">
					<div class="ui six wide computer sixteen wide mobile column form mt-2 ml-1">
						<div class="field login-field">
							<label>Adresse e-mail</label>
							<input type="text" v-model.trim="username" @keyup.enter="onLoginClick" placeholder="Adresse e-mail ...">
						</div>
						<div class="field login-field">
							<label>Mot de passe</label>
							<input type="password" v-model.trim="password" @keyup.enter="onLoginClick" placeholder="Mot de passe ...">
							<div class="flex">
								<div @click="()=>$modal.show('forget-password')" class="ml-auto forgot-pass">Mot de passe oublié?</div>
							</div>
						</div>
						<div class="flex">
							<button class="mx-auto login-button" @click="onLoginClick">Se connecter</button>
						</div>
					</div>
				</div>
			</div>
		</template>
    <reset-password />
	</div>
</template>

<script>
	import navbar from "@/components/landingNav.vue"
	import resetPassword from "@/components/reset-password.vue"

	export default {
		name: 'login',
		components: {
			navbar, resetPassword
		},
		data() {
			return {
				username: '',
				password: ''
			}
		},
		methods: {
			onLoginClick() {
				console.log('onLoginClick.');
				this.$store.dispatchAsync(this.$SHARED.services.login, {
					username: this.username,
					password: this.password
				}).then((data) => {
					this.$store.commit('SET_CURRENT_USER', data.user)

					let dossier = data.dossier

					if (_.isEmpty(dossier)){
						console.log("dossier empty ==> process inscription")
						this.$router.push({name: 'dossier-upload'});
					}
					else if (dossier.reinscription && dossier.reinscription.status && dossier.reinscription.status === 'validated'){
						console.log("student already reinscrit ==> process etude")
						this.$router.push({name: 'espace-etude'});
					}
					else if (dossier.reinscription){
						console.log("student not reinscrit ==> process reinscription")
						this.$router.push({name: 'espace-reinscription'});
					}
					else if (dossier.inscription && dossier.inscription.status && dossier.inscription.status === 'validated'){
						console.log("student already inscrit ==> process etude")
						this.$router.push({name: 'espace-etude'});
					}
					else {
						console.log("==> process inscription")
						this.$router.push({name: 'dossier-upload'});
					}
				});
			}
		}
	}

</script>

<style scoped>
	.landing-bg {
		background-image: url("../assets/images/bg_landing.jpg") !important;
		background-size: cover;
		/* height: 100%; */
		/* background-color: var(--main-color-red); */
	}

	.full-page {
		/* display: flex;
	  align-items: flex-start;
	  flex-direction: column;
	  justify-content: center; */
		height: 100vh;
		width: 100vw;
	}

	.landing-hero {
		margin-top: 6em;
		padding: 0em 2em;
	}

	.login-small-title {
		font-size: 1.5em;
		font-weight: 700;
		text-transform: uppercase;
		line-height: 1;
		color: var(--main-color-blue);
	}

	.login-title {
		font-size: 3.3em;
		font-weight: 400 !important;
		text-transform: uppercase;
		line-height: 1;
		color: var(--main-color-blue);
	}

	.login-field > label {
		font-weight: 300 !important;
		color: var(--main-color-blue) !important;
		font-size: 1.5em !important;
	}

	.login-field > input {
		color: var(--main-color-blue);
		padding: 1em 0.5em !important;
		background-color: #F4F9FB !important;
		border: 2px solid #0d458472 !important;
		border-radius: 0px !important;
	}

	.login-field > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: rgba(13, 68, 132, 0.5);
	}

	.forgot-pass {
		color: var(--main-color-blue);
		text-decoration: underline;
		margin-top: 0.5em;
		font-weight: 600;
		cursor: pointer;
	}

	.login-button {
		padding: 1em 2em;
		background-color: var(--main-color-blue);
		color: #FFFFFF;
		font-weight: 700;
		font-size: 1.5em;
		/* text-transform: uppercase; */
		cursor: pointer;
		line-height: 1;
		border: none;
	}
	@media all and (max-width:700px){
		.login-title{
			font-size: 2.5em;
			text-align: center;
		}
		.login-button {
			padding: 0.5em 1em;
			background-color: var(--main-color-blue);
			color: #FFFFFF;
			font-weight: 700;
			font-size: 1.3em;
		}
	}
</style>
